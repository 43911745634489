<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import Canvas from "./canvas/Canvas.svelte";
  import ButtonProgress from "./canvas/ButtonProgress.svelte";

  let dispatch = createEventDispatcher();

  export let duration = 2000; // Required press duration in ms
  export let active = false;

  let pressed: boolean = false;
  let progress: number = 0;
  let timer: any = null;
  let startTime: number;
  let w, h;
  let button: HTMLButtonElement;
  let color: string;

  function start(e: PointerEvent) {
    if (active) return;
    pressed = true;
    startTime = performance.now();
    timer = setInterval(() => {
      let elapsed_ms = performance.now() - startTime;
      progress = (100 * elapsed_ms) / duration;
      if (elapsed_ms >= duration) {
        stop(e);
        dispatch("click");
      }
    }, 16);
  }

  function stop(e: PointerEvent) {
    if (timer) {
      clearInterval(timer);
      timer = null;
      pressed = false;
      progress = 0;
    }
  }

  $: {
    if (button) {
      color = getComputedStyle(button).getPropertyValue("--primary");
    }
  }
</script>

<button
  bind:this={button}
  bind:clientWidth={w}
  bind:clientHeight={h}
  class:active
  class:pressed
  on:pointerdown|preventDefault={start}
  on:pointercancel|preventDefault={stop}
  on:pointerup|preventDefault={stop}
  on:pointerleave|preventDefault={stop}
  {...$$restProps}
>
  {#if pressed}
    <Canvas width={w} height={h}>
      <ButtonProgress width={w} height={h} {progress} {color} />
    </Canvas>
  {/if}

  <slot />
</button>

<style>
  button {
    background-color: gray;
    position: relative;
    transition: background-color 0.2s;
  }
  button.active {
    background-color: var(--active-mode-background);
  }
  /* button.active:hover {
    filter: brightness(120%);
  } */

  :global(button > canvas) {
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  :global(svg) {
    color: var(--svg-color);
  }
</style>
