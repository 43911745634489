<script lang="ts">
  import { getContext, onMount } from "svelte";
  import { _, time, date } from "svelte-i18n";
  import hmi, { hasPendingAlarm } from "@/hmi";

  import LanguageDropdown from "@/lib/LanguageDropdown.svelte";

  let lastSeconds = -1;

  const { subTitle } = getContext<AppContext>("app");

  // function pad2(n) {
  //     if (n <= 9) return "0" + n;
  //     return n;
  // }

  const s_mode = hmi.getValueStore("set.MODE");
  let dateUpdate: Date;

  function update() {
    let now = new Date();
    if (lastSeconds != now.getSeconds()) {
      lastSeconds = now.getSeconds();
      dateUpdate = now;
      // time = `${now.getFullYear()}-${pad2(now.getMonth() + 1)}-${pad2(
      //     now.getDate()
      // )} ${pad2(now.getHours())}:${pad2(now.getMinutes())}:${pad2(
      //     now.getSeconds()
      // )}`;
    }
  }

  onMount(() => {
    const interval = setInterval(update, 100);
    return () => clearInterval(interval);
  });

  update();

  function alarmsClicked() {
    hmi.ackAlarm();
    alert("FIXME: Show alarmlist!");
  }
</script>

<div class="header">
  <div class="logo">
    <img src="/img/lillnord-logo.png" alt="lillnord logo" draggable="false" />
  </div>
  <div class="sub-title">{$subTitle}</div>
  <div class="middle">
    {#if $hasPendingAlarm}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <img
        on:click={alarmsClicked}
        src="/icons/alarm.svg"
        alt="alarm indicator"
        class="blink"
        draggable="false"
        width="32px"
        style="margin-top: -5px; margin-right:20px"
      />
    {/if}
    <LanguageDropdown />
  </div>
  <div class="clock">
    {$date(dateUpdate, { format: "medium" })}
    &nbsp;
    {$time(dateUpdate, { format: "medium" })}
  </div>
</div>

<style>
  .header {
    display: flex;
    gap: 20px;
    padding: 5px;
    background-color: #222;
  }
  .logo img {
    max-height: 26px;
  }
  .sub-title {
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
  }
  .middle {
    text-align: right;
  }
  .clock {
    text-align: right;
  }
</style>
