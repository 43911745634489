<script lang="ts">
  import { linear } from "@/utils.js";
  import { setContext } from "svelte";
  import { writable } from "svelte/store";

  export let min: number;
  export let max: number;
  export let period: number;
  export let height: number = 200;
  export let marker: number | undefined = undefined;
  export let enableUpdate: boolean = true;

  let clientHeight: number;
  let markerY: number;
  let enableUpdateStore = writable<boolean>(enableUpdate);

  setContext<GraphContext>("graph", {
    min,
    max,
    period,
    height,
    enableUpdate: { subscribe: enableUpdateStore.subscribe },
  });

  $: if (clientHeight && marker !== undefined) markerY = linear([min, max], [clientHeight, 0])!(marker);
  $: enableUpdateStore.set(enableUpdate);
</script>

<div class="graph" bind:clientHeight>
  {#if markerY !== undefined}
    <svg>
      {#each [60, 50, 40, 30, 20, 10, 0] as v, i}
        <line
          x1={(100 / 6) * i + "%"}
          y1="0"
          x2={(100 / 6) * i + "%"}
          y2="100%"
          text-anchor="middle"
          style="stroke:rgb(90,90,90);stroke-width:1"
        />
        <text y="102%" dominant-baseline="hanging" font-size="12px">
          <tspan x={(100 / 6) * i + "%"} fill="rgb(90,90,90)" text-anchor="middle">{-v}</tspan>
        </text>
      {/each}
      <line x1="0" y1={markerY} x2="100%" y2={markerY} style="stroke:rgb(90,90,90);stroke-width:1" />
    </svg>
  {/if}
  <slot />
</div>

<style lang="scss">
  .graph {
    position: relative;
    svg {
      z-index: -1;
      overflow: visible;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
</style>
