<script lang="ts">
  import hmi, { config } from "@/hmi";
  import { _ } from "svelte-i18n";
</script>

<div class="center-page">
  <div class="value-entry-table">
    <label for="x1">Afbagningstidspunkt:</label>
    <input value="04.30" id="x1" type="text" readonly />

    <label style="" for="x2">Optøningstid:</label>
    <input value="315" id="x2" type="text" readonly />
    <span>minutter</span>

    <label for="x3">Lager temperatur:</label>
    <input value="2" id="x3" type="text" readonly />
    <span>{$config?.tempUnit}</span>
  </div>
</div>

<style>
  .center-page {
    display: flex;
    height: 100%;
    max-width: 100%;
  }
  .center-page > * {
    margin: auto;
    width: fit-content;
  }

  label {
    cursor: pointer;
    grid-column-start: 1;
    margin: 8px 5px 2px 5px;
  }
  input[type="text"] {
    width: 5rem;
    height: 2rem;
    padding: 0px;
    text-align: center;
    cursor: pointer;
    margin: 5px;
  }

  .value-entry-table {
    width: 22rem;
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    vertical-align: middle;
    gap: 10px;
  }
  span {
    display: inline-block;
    margin: 8px 5px 2px 5px;
  }
</style>
