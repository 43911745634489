<script lang="ts">
  import { onMount, getContext } from "svelte";
  import { fade } from "svelte/transition";

  export let label: string | undefined = undefined;
  export let icon: string | undefined = undefined;

  const { addTab, active } = getContext<TabsContext>("tabs");

  let index = -1;
  onMount(() => {
    index = addTab(label, icon);
  });
</script>

{#if $active === index}
  <div transition:fade>
    <slot />
  </div>
{/if}

<style>
  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>
