<script lang="ts">
  import { getContext } from "svelte";
  import { fade } from "svelte/transition";
  import hmi, { Modes } from "@/hmi";
  import LampSvg from "@/svg/LampSvg.svelte";
  import OnOffSvg from "@/svg/OnOffSvg.svelte";
  import dialogs from "./dialogs/index.js";
  import ModeButton from "./ModeButton.svelte";

  const s_mode = hmi.getValueStore("set.MODE");

  const { config } = getContext<AppContext>("app");

  $: mode = $s_mode;
  $: switchDelay = $config.mode_switch_click_duration_ms || 2000;
  $: lightDelay = $config.mode_light_click_duration_ms || 500;

  function switchMode(m: number) {
    hmi.setValue("set.MODE", m);
    mode = m;
  }

  let s_light = hmi.getValueStore("LIGHT");
  function toggleLight() {
    hmi.setValue("LIGHT", !$s_light ? 1 : 0);
  }

  function settingsClicked() {
    dialogs.systemSettings();
  }
</script>

<aside class="mode-selector">
  <div class="modes" style="--active-mode-background: var(--primary)">
    <ModeButton
      on:click={() => switchMode(Modes.OFF)}
      active={mode === Modes.OFF}
      duration={switchDelay}
      --active-mode-background={mode === Modes.OFF ? "gray" : ""}
      --svg-color={mode !== Modes.OFF ? "var(--hmi-mode-fill-color)" : ""}
      style="margin-bottom: 10px"
    >
      <OnOffSvg />
    </ModeButton>

    <ModeButton on:click={() => switchMode(Modes.AUTO)} active={mode === Modes.AUTO} duration={switchDelay}>
      <img src="/icons/pm/iconAuto.min.svg" alt="auto mode" draggable="false" />
    </ModeButton>
    <ModeButton on:click={() => switchMode(Modes.STORAGE)} active={mode === Modes.STORAGE} duration={switchDelay}>
      <img src="/icons/pm/iconCooling.min.svg" alt="cooling mode" draggable="false" />
    </ModeButton>
    <ModeButton on:click={() => switchMode(Modes.PROOFING)} active={mode === Modes.PROOFING} duration={switchDelay}>
      <img src="/icons/pm/iconProofing.min.svg" alt="proofing mode" draggable="false" />
    </ModeButton>
  </div>

  <div class="functions">
    <ModeButton on:click={() => toggleLight()} duration={lightDelay}>
      <LampSvg style="color: {$s_light ? 'var(--hmi-mode-fill-color)' : ''}" />
    </ModeButton>
    <button on:click={settingsClicked}>
      <img src="/icons/menu.svg" alt="menu" width="70%" style:padding-top="15px" draggable="false" />
    </button>
  </div>
</aside>

<style>
  .mode-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    background-color: #222;
    width: max-content;
  }
  .mode-selector > * {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .mode-selector > *:last-child {
    display: inline-block;
    position: absolute;
    bottom: 0;
  }

  /* a, */
  :global(.mode-selector button) {
    border: none;
    padding: 5px;
    margin-bottom: 0;
    min-width: var(--menu-width);
    min-height: var(--menu-width);
  }

  :global(.mode-selector button > img) {
    max-width: calc(var(--menu-width) - (5px * 2));
    max-height: calc(var(--menu-width) - (5px * 2));
  }
  :global(.mode-selector button > svg) {
    max-width: calc(var(--menu-width) - (5px * 2));
    max-height: calc(var(--menu-width) - (5px * 2));
  }

  .functions > * {
    background-color: gray;
    margin-top: 10px;
    padding: 5px;
  }

  /* HACK: Image preload so menu does not flicker when changing button img from js */
  /*
   :global(body::after) {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(/icons/__open-padlock.png) url(/icons/__closed-padlock.png);
  }
  */
</style>
