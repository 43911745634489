<script lang="ts">
  import { fly, fade } from "svelte/transition";

  export let mode: number;
  export let selected: boolean = false;

  let prevMode = mode;
  let direction: number;

  $: {
    direction = mode > prevMode ? 1 : -1;
    prevMode = mode;
  }
</script>

{#if selected}
  <div class="view" class:selected in:fly={{ y: 100 * direction }} out:fly={{ y: -100 * direction }}>
    <slot />
  </div>
{/if}

<style>
  .view {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .view.selected {
    pointer-events: all;
  }
</style>
