<script lang="ts">
  import { SvelteToast, toast } from "@zerodevx/svelte-toast";
  import EventBus from "js-event-bus";
  import { onMount, setContext } from "svelte";
  import { json, isLoading, locale, register, _, getLocaleFromNavigator, locales, addMessages } from "svelte-i18n";
  import { closeAllModals, closeModal, Modals } from "svelte-modals";
  import { writable } from "svelte/store";
  import { fade } from "svelte/transition";

  import Header from "@/lib/Header.svelte";
  import ModeSelector from "@/lib/ModeSelector.svelte";
  import ScreenLock from "@/lib/ScreenLock.svelte";

  import demoConfig from "@/demo-config.json";
  import ModeDisplay from "@/lib/ModeDisplay.svelte";
  import hmi, { config as hmiConfig, farenheight } from "@/hmi";

  let locked = false;
  let idleCounter = 0;
  let eventBus = new EventBus();
  let background = writable<string | null>(null);

  let s_mode = hmi.getValueStore("set.MODE");
  let s_config = writable<HMIConfig | undefined>(undefined);

  // FIXME: Remove demo config at some point
  $: if ($hmiConfig) {
    let cfg = { ...demoConfig, ...$hmiConfig };
    s_config.set(cfg);
    onNewConfig(cfg);
  }

  register("en-GB", () => import("./i18n/en.json"));
  register("en-US", () => import("./i18n/en.json"));
  addMessages("en-US", { use_farenheight_temps: "true" });
  register("da-DK", () => import("./i18n/da-DK.json"));
  register("fi-FI", () => import("./i18n/fi-FI.json"));

  selectLocale();

  setContext<AppContext>("app", {
    eventBus,
    config: s_config,
    background,
    subTitle: writable(""),
  });

  $: $farenheight = $json("use_farenheight_temps") as boolean;

  function onNewConfig(newConfig: HMIConfig | null) {
    if (!newConfig) return;

    const style = document.querySelector<HTMLBaseElement>(":root")?.style;
    if (!style) return;

    const theme = newConfig?.theme;
    if (theme) {
      style.setProperty("--hmi-background-opacity", theme["background_opacity"]);
      style.setProperty("--primary", theme["primary_color"]);
      style.setProperty("--primary-inverse", theme["primary_inverse_color"]);
      style.setProperty("--secondary-color", theme["secondary_color"]);
      style.setProperty("--secondary-color-inverse", theme["secondary_inverse_color"]);
      style.setProperty("--active-link", theme["secondary_color"]); // FIXME: Rename css variable
    }
  }

  function selectLocale() {
    let match;

    // Get user selected locale is any selected
    let userLocale = localStorage.getItem("hmi.locale")?.toLowerCase() || undefined;
    if (!userLocale) {
      // Get browser locale settings
      userLocale = getLocaleFromNavigator()?.toLowerCase();
    }
    if (userLocale) {
      // 1st try perfect match
      match = $locales.find((v) => v.toLowerCase() === userLocale);
      if (!match) {
        // 2nd try matching the start
        match = $locales.find((v) => v.toLowerCase().startsWith(userLocale!));
        if (match) {
          console.log("found partial locale match:", userLocale, "=>", match);
        }
      } else {
        console.log("found perfect locale match:", match);
      }
    }
    if (!match) {
      console.log("found no locale match. Using en-GB as fallback");
      match = "en-GB";
    }
    locale.set(match || "en-GB");
  }

  onMount(() => {
    let timer = setInterval(() => {
      if (!$s_config) return;

      idleCounter = Math.min(idleCounter + 1, 60 * 60 * 24 * 365); // Max at 1 year
      if (idleCounter === $s_config.idle_user_timeout_seconds) {
        closeAllModals();
        window.location.hash = "/";
        eventBus.emit("user-idle");
      }
      // FIXME: Only lock screen if time is inside a configurable range
      if (idleCounter === $s_config.idle_user_lockscreen_seconds) {
        locked = true;
        toast.push($_("screen_locked"), {
          duration: 10000,
        });
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function resetIdleCount() {
    idleCounter = 0;
  }
</script>

<!-- prevent browser context menu from showing -->
<svelte:body on:contextmenu|preventDefault />

<svelte:window on:keydown|passive={resetIdleCount} on:pointermove|passive={resetIdleCount} on:click|passive={resetIdleCount} />

<div class="hmi-container">
  {#if !$isLoading && $hmiConfig !== null}
    <Header />
    {#if $background}
      {#key $background}
        <div transition:fade>
          <img class="background" src={$background} draggable="false" />
          <!-- <InlineSVG src={$background} /> -->
        </div>
      {/key}
    {/if}
    <div class="wrapper">
      <ModeSelector />
      <ScreenLock bind:locked />
      <div class="content">
        <!-- MAIN CONTENT! -->
        <main>
          <ModeDisplay mode={$s_mode} />
        </main>
      </div>
    </div>
  {/if}
</div>

<Modals>
  <div slot="backdrop" class="backdrop" transition:fade on:click={closeModal} />
</Modals>

<div class="wrap-toast">
  <SvelteToast options={{ reversed: true, intro: { y: 192 } }} />
</div>

<style>
  .hmi-container {
    position: relative;
    overflow: hidden; /* prevent scrollbar from showing when doing fly transitions */
  }

  .background {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: var(--hmi-background-opacity);
  }

  .wrapper {
    flex-grow: 1;
    display: grid;
    grid-template-columns: var(--menu-width) calc(100% - (var(--menu-width) + 5px * 2));
    gap: 10px;
  }

  .wrapper .content {
    position: relative;
  }

  :global(:root) {
    --toastContainerZIndex: 20000;
    --toastColor: white;
    --toastContainerTop: auto;
    --toastContainerRight: 2rem;
    --toastContainerBottom: 1rem;
    --toastContainerLeft: auto;
    --toastBackground: #222;
    --toastBarBackground: var(--primary-dark);
  }
  :global(.wrap-toast progress) {
    margin: 0;
  }
  :global(.wrap-toast li) {
    list-style-type: none;
  }
  :global(.wrap-toast div[role="button"]) {
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: var(--primary);
  }
</style>
