<script lang="ts">
  import { onDestroy, onMount, setContext } from "svelte";

  export let width: number;
  export let height: number;
  export let center = false;
  export let contextName = "canvas";

  let canvas: any;
  let ctx: CanvasRenderingContext2D;
  let drawFuntions: DrawCallback[] = [];
  let pendingInvalidation = false;
  let frameId: number;

  onMount(() => {
    ctx = canvas.getContext("2d");
  });

  onDestroy(() => {
    if (frameId) cancelAnimationFrame(frameId);
  });

  $: setContext(contextName, {
    register(fn: DrawCallback) {
      drawFuntions.push(fn);
    },
    deregister(fn: DrawCallback) {
      drawFuntions.splice(drawFuntions.indexOf(fn), 1);
    },
    invalidate() {
      if (pendingInvalidation) return;
      pendingInvalidation = true;
      frameId = requestAnimationFrame(update);
    },
  });

  function update() {
    if (!ctx) return;

    if (center) ctx.clearRect(-width / 2, -height / 2, width, height);
    else ctx.clearRect(0, 0, width, height);

    drawFuntions.forEach((fn) => {
      ctx.save();
      fn(ctx);
      ctx.restore();
    });
    pendingInvalidation = false;
  }

  function scaleCanvas(canvas: any, ctx: CanvasRenderingContext2D, width: number, height: number, center: boolean) {
    // FIXME: What to do here?
  }

  $: if (canvas && ctx) scaleCanvas(canvas, ctx, width, height, center);
</script>

<canvas bind:this={canvas} {width} {height} />

<slot />
