<script lang="ts">
  import { client } from "@/hmi/index.js";
  import { onMount } from "svelte";
  import { _, time, date } from "svelte-i18n";

  let events: HMIEvents[] = [];

  onMount(() => {
    client
      .invoke("ReadEvents", 0, 1000)
      .then((data) => (events = data as HMIEvents[]))
      .catch((e) => {
        console.error(e);
      });
  });
</script>

<div class="events">
  {#each events as e}
    {@const ts = new Date(e.ts * 1000)}
    <div>
      {$date(ts, { format: "medium" })}
      {$time(ts, { format: "medium" })}
    </div>
    <div>{e.ty}</div>
    <div>{e.d ?? ""}</div>
  {/each}
</div>

<style lang="scss">
  .events {
    padding: 10px;
    max-height: 100%;
    display: grid;
    overflow-y: auto;
    grid-template-columns: auto auto 1fr;
    column-gap: 20px;
    scroll-snap-type: y proximity;
    > div:nth-child(3n + 1) {
      scroll-snap-align: start;
    }
    > div:nth-child(3n + 2) {
      color: gray;
    }
  }
</style>
