<script lang="ts">
  import hmi from "@/hmi";
  import { numFmt } from "@/utils";
  import { config } from "@/hmi";
  import { _ } from "svelte-i18n";
  import { Swiper, SwiperSlide } from "swiper/svelte";
  import Setpoint from "./components/Setpoint.svelte";
  import { getContext, onMount } from "svelte";
  import ProofingDetails from "./ProofingDetails.svelte";

  const SETPREFIX = "set.proofing.";

  const { background, subTitle } = getContext<AppContext>("app");
  onMount(() => {
    $background = "/img/backgrounds/proofing.png";
  });

  $: $subTitle = $_("Proofing");

  let s_curTemp = hmi.getValueStore("cur.T");
  let s_setTemp = hmi.getValueStore(SETPREFIX + "T");
  let s_curHumidity = hmi.getValueStore("cur.H");
  let s_setHumidity = hmi.getValueStore(SETPREFIX + "H");

  let curTempText: any, curHumidityText: any;
  $: {
    let v = $s_curTemp;
    curTempText = v !== null ? $numFmt(v, $config?.tempDecimals) + $config?.tempUnit || "" : "-";
  }
  $: {
    let v = $s_curHumidity;
    curHumidityText = v !== null ? $numFmt(v, 0) + "%" : "-";
  }

  $: setTemp = $s_setTemp;
  $: setHumidity = $s_setHumidity;

  function commitTemperatur() {
    hmi.setValue(SETPREFIX + "T", setTemp);
  }
  function commitHumidity() {
    hmi.setValue(SETPREFIX + "H", setHumidity);
  }
</script>

<Swiper>
  <SwiperSlide>
    <div class="proofing-mode" style="margin-top: 40px">
      <!-- Temperatur -->
      <div class="row">
        <div class="cur-value">{curTempText}</div>
        <Setpoint
          bind:value={setTemp}
          name={$_("Temperature") + " " + $_("setpoint")}
          units={$config?.tempUnit || ""}
          range={$config?.proofing.Trange}
          on:change={commitTemperatur}
        />
      </div>

      <!-- humidity -->
      <div class="row">
        <div class="cur-value">{curHumidityText}</div>
        <Setpoint
          bind:value={setHumidity}
          name={$_("Humidity") + " " + $_("setpoint")}
          units="%"
          range={$config?.proofing.Hrange}
          on:change={commitHumidity}
        />
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <!-- FIXME: Why can't we use 100% here? -->
    <div style="margin-top: 35px; height: 85%">
      <ProofingDetails />
    </div>
  </SwiperSlide>
</Swiper>

<style lang="scss">
  .proofing-mode {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }
  .proofing-mode:first-child {
    margin-top: 10px;
  }

  .row {
    display: flex;
  }
  .row {
    margin-bottom: 10px;
  }
  .cur-value {
    padding-bottom: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 8em;
    width: 60%;
  }
</style>
