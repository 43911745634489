<script lang="ts">

  let startX: number, mouseX: number, scaleX: number;
  let dragging: SVGElement | undefined;
  let divWidth: number;
  let dragIndex: number;
  let svgElement: SVGSVGElement;
  let xpos = [100, 600, 1400, 2200];

  $: scaleX = svgElement?.viewBox.baseVal.width / divWidth;

  function dragStart(e: PointerEvent) {
    dragging = e.target as SVGElement;
    dragIndex = +(dragging.getAttribute("data-idx") || "-1");
    dragging.setPointerCapture(e.pointerId);
    startX = +(dragging.getAttribute("x") || "0");
    mouseX = e.pageX;
  }

  function dragMove(e: PointerEvent) {
    if (!dragging) return;
    let x = (e.pageX - mouseX) * scaleX + startX;
    x = Math.max(60, Math.min(svgElement.viewBox.baseVal.width - 140, x));
    xpos[dragIndex] = x;
  }

  function dragEnd(e: PointerEvent) {
    if (!dragging) return;
    dragging.releasePointerCapture(e.pointerId);
    dragging = undefined;
    dragIndex = -1;
  }

  function fmt2(n: number) {
    // FIXME: Use locale for this so we at least get am/pm
    if (n < 10) return `0${n}`;
    return `${n}`;
  }

  function getTimeFromX(x: number) {
    x -= 60;
    let m = Math.floor((x / 100) * 60) % 60;
    let h = Math.floor(x / 100);
    return fmt2(h) + ":" + fmt2(m);
  }
</script>

<h4 style:text-align="center">Defrost schedule</h4>

  <div
    bind:clientWidth={divWidth}
    style:width="100%"
    style:margin="0 auto"
    on:pointerdown|preventDefault={dragStart}
    on:pointermove|preventDefault={dragMove}
    on:pointerup|preventDefault={dragEnd}
    on:pointercancel|preventDefault={dragEnd}
  >
    <svg bind:this={svgElement} viewBox="0 0 2600 800">
      <rect x="100" y="150" width={24 * 100} height="300" style="fill: none; stroke: gray" />
      {#each Array(25) as _, i}
        <line x1={100 + i * 100} x2={100 + i * 100} y1="450" y2={i % 4 === 0 ? 495 : 480} stroke="gray" />
        {#if i % 4 === 0}
          <text x={100 + i * 100} y="550">{fmt2(i) + ":00"}</text>
        {/if}
      {/each}
      {#each xpos as x, i}
        <g class="drag">
          <rect data-idx={i} {x} y="10" width="80" height="80" />
          <line x1={x + 40} x2={x + 40} y1="90" y2="500" style:pointer-events="none" />
          <text x={x + 40} y="70">{i + 1}</text>
        </g>
      {/each}
    </svg>
  </div>
  <div style:padding-left="60px">
    {#each xpos as _, i}
      <div>#{i + 1}: &nbsp;{getTimeFromX(xpos[i])}</div>
    {/each}
</div>

<style>
  svg {
    stroke-width: 10px;
  }
  .drag {
    pointer-events: bounding-box;
    cursor: grab;
  }
  .drag rect {
    stroke: green;
    fill: darkcyan;
  }
  .drag text {
    pointer-events: none;
  }
  .drag line {
    stroke: green;
  }

  text {
    text-anchor: middle;
    font: 50px sans-serif;
  }
</style>
