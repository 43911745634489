<script lang="ts">
  import { date, time } from "svelte-i18n";
  import { client } from "@/hmi";
  import { getContext, onMount } from "svelte";

  let { done } = getContext<DialogContext>("dialog");

  let alarms: HMIAlarm[] = [];
  let show = true;
  let dialogRef: HTMLDialogElement;

  client
    .invoke<HMIAlarm[]>("ReadAlarms", 0, 100)
    .then((data) => (alarms = data))
    .catch((e) => alert(e));

  onMount(() => {
    dialogRef.showModal();
  });
</script>

{#if show}
  <dialog
    bind:this={dialogRef}
    on:cancel={() => {
      show = false;
      done(undefined);
    }}
    on:close={() => {
      show = false;
      done(dialogRef.returnValue);
    }}
  >
    <p class="alarms">
      {#each alarms as e}
        {@const ts = new Date(e.ts * 1000)}
        <div>
          {$date(ts, { format: "medium" })}
          {$time(ts, { format: "medium" })}
        </div>
        <div>{e.num}</div>
        <div>{e.id}</div>
      {/each}
    </p>
    <button on:click={() => dialogRef.close()}>Close</button>
  </dialog>
{/if}

<style lang="scss">
  dialog {
    position: relative;
    width: 100%;
    height: 100%;
    button {
      position: absolute;
      width: fit-content;
      bottom: 0px;
      right: 10px;
    }
  }
  .alarms {
    margin: 10px;
    padding: 10px;
    width: 100%;
    display: grid;
    overflow-y: auto;
    grid-template-columns: auto auto 1fr;
    column-gap: 20px;
    scroll-snap-type: y proximity;
    > div:nth-child(3n + 1) {
      scroll-snap-align: start;
    }
    > div:nth-child(3n + 2) {
      color: gray;
    }
  }
</style>
