<script lang="ts">
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import { decimalSeparator } from "@/utils";

  export let done: (result: number | null) => void;
  export let title: string;
  export let value: number;
  export let options: string[] = [];
  export let result: number | null = null;

  let dialog: HTMLDialogElement | undefined;
  let input: HTMLInputElement | undefined;
  let origValue = value;
  let allowBackspace = false;

  onMount(() => {
    dialog?.showModal();
  });

  function onPointerDown(event: MouseEvent) {
    if (!input || !event.target) return;
    let el = event.target as HTMLButtonElement;
    if (!el) return;
    if (el.tagName !== "BUTTON") return;
    let fn = el.getAttribute("data-fn");
    if (fn) {
      switch (fn) {
        case "neg":
          if (input.value.slice(0, 1) !== "-") input.value = "-" + input.value;
          else input.value = input.value.slice(1);
          break;
        case "del":
          if (input.value.length > 0) {
            input.value = input.value.slice(0, -1);
          }
          break;
      }
    } else {
      let text = el.textContent;
      if (text === $decimalSeparator && input.value.indexOf($decimalSeparator) !== -1) return;
      input.value += text;
    }

    if (input.value.length === 1) {
      allowBackspace = true;
    } else if (input.value.length === 0) allowBackspace = false;

    result = input.value.length > 0 ? +input.value : null;
  }
</script>

<dialog bind:this={dialog} class="numpadDialog" on:close|stopPropagation={() => done(result)} on:cancel|stopPropagation={() => done(null)}>
  <form method="dialog">
    <div class="title">{title}</div>
    <input readonly class="input" placeholder={"" + origValue} bind:this={input} />
    <div class="keys" on:pointerdown={onPointerDown} on:click|preventDefault={() => {}}>
      <button>7</button>
      <button>8</button>
      <button>9</button>
      <button data-fn="del" disabled={!allowBackspace}> &#x232B </button>
      <button>4</button>
      <button>5</button>
      <button>6</button>
      <button style="grid-column: 1">1</button>
      <button>2</button>
      <button>3</button>
      <button style="grid-column: 1 / span 2">0</button>
      <button disabled={options.findIndex((s) => s === "digit") === -1}>{$decimalSeparator}</button>
      <button data-fn="neg" disabled={options.findIndex((s) => s === "neg") === -1}>+/-</button>
    </div>
    <div class="footer">
      <button value={null}>{$_("cancel")}</button>
      <button {value} autofocus>{$_("ok")}</button>
    </div>
  </form>
</dialog>

<style>
  .numpadDialog {
    text-align: center;
    overflow: hidden;
  }

  .input {
    padding: 0px;
    height: 2rem !important;
    text-align: center;
  }

  .keys {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--keys-gap);
    margin-bottom: 1rem;
  }

  .keys * {
    text-align: center;
    min-width: var(--keys-width);
    margin-bottom: 0;
    padding: 10px;
  }

  .footer {
    display: flex;
    gap: var(--keys-gap);
  }

  .footer > * {
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 0;
  }
</style>
