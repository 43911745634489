<script lang="ts">
  import { createEventDispatcher, setContext } from "svelte";
  import { writable } from "svelte/store";

  const dispatch = createEventDispatcher();

  type TabData = {
    icon: string | undefined;
    label: string | undefined;
  };

  let tabs: TabData[] = [];
  let active = writable(0);

  setContext<TabsContext>("tabs", {
    active,
    addTab: function (label, icon) {
      tabs = [...tabs, { label, icon }];
      return tabs.length - 1;
    },
  });

  function setActive(index: number) {
    $active = index;
    dispatch("change", index);
  }
</script>

<div class="tabs" {...$$restProps}>
  <div class="tab-content">
    <slot />
  </div>
  <div class="tab-selectors">
    {#each tabs as tab, index}
      <button on:click={() => setActive(index)} class:active={$active === index}>
        {#if tab.icon}
          <img src={tab.icon} draggable="false" />
        {:else}
          {tab.label}
        {/if}
      </button>
    {/each}
    <div class="extra">
      <slot name="tabs-extra" />
    </div>
  </div>
</div>

<style>
  .tabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .tab-content {
    position: relative;
    padding: 10px;
    background-color: black;
    border: 1px solid gray;
    border-radius: 2px 0 2px 2px;
    flex-grow: 1;
  }
  .tab-selectors {
    display: flex;
    flex-direction: column;
    margin-left: -1px;
    width: 65px;
  }
  button {
    background-color: transparent;
    border: none;
    color: white;
    border-radius: 0 2px 2px 0;
    padding: 10px;
    height: 65px;
    margin: 0;
  }
  button.active {
    background-color: black;
    border-right: 1px solid gray;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
  .extra {
    margin-top: auto;
    margin-bottom: 0;
  }
</style>
