<script lang="ts">
  import { onMount } from "svelte";
  import { getTimeFormatter, _ } from "svelte-i18n";
  import { delay } from "@/utils.js";
  import { Tabs, Tab } from "@/lib/Tabs";

  import Defrost from "./Defrost.svelte";
  import Auto from "./Auto.svelte";
  import Events from "./Events.svelte";
  import hmi from "@/hmi/index.js";

  export let done: (result: number | null) => void;

  let dialog: HTMLDialogElement;
  const s_brightness = hmi.getValueStore("config.Brightness");

  onMount(async () => {
    dialog?.showModal();

    // FIXME: Hack to prevent annoying resize when dialog is shows
    await delay(0);
    dialog.classList.remove("new");
  });

  function brightnessChanged(e: any) {
    let v = e.target.value;
    hmi.setValue("config.Brightness", +v);
  }
</script>

<dialog
  bind:this={dialog}
  class="settings-dialog new"
  on:close|stopPropagation={() => done(null)}
  on:cancel|stopPropagation={() => done(null)}
>
  <Tabs>
    <button class="exit" on:click={() => dialog.close()} slot="tabs-extra"><img src="/icons/close.png" draggable="false" /></button>

    <Tab icon="/icons/settings/auto.svg">
      <Auto />
    </Tab>
    <Tab icon="/icons/settings/proofing.svg">
      <h1>Proofing</h1>
    </Tab>
    <Tab icon="/icons/settings/defrost.svg">
      <Defrost />
    </Tab>
    <Tab icon="/icons/settings/alarm.svg">
      <Events />
    </Tab>
    <Tab icon="/icons/settings/configuration.svg">
      <h1>Configuration</h1>
    </Tab>
    <Tab icon="/icons/settings/system.svg">
      <div style="margin: 20px">
        <h1>System</h1>
        <label
          >Screen Brightness:
          <input type="range" min="50" max="99" value={$s_brightness} on:change={brightnessChanged} />
        </label>
      </div>
    </Tab>
  </Tabs>
</dialog>

<style>
  dialog {
    padding: 10px;
    width: 100%;
    height: 100%;
  }
  h1 {
    text-align: center;
  }
  .exit {
    margin: 0;
    background-color: transparent;
    border: none;
    color: white;
  }
</style>
