<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { numFmt, clamp } from "@/utils";
  import dialogs from "@/lib/dialogs";

  const dispatch = createEventDispatcher();

  export let name: string;
  export let value: number;
  export let range: number[];
  export let units: string;

  let slider: HTMLInputElement;
  let container: HTMLDivElement;

  // Get the transform scale of an element
  function getScale(e: HTMLElement): number {
    const transform = window.getComputedStyle(e).transform;
    let m = new DOMMatrixReadOnly(transform);
    return m.c;
  }

  onMount(() => {
    // Set the height of the rotated slider to the height of the parent element
    slider.style.width = container.parentElement!.clientHeight / getScale(slider) + "px";
  });

  async function valueClick() {
    let result = await dialogs.numpad(name, range[0] < 0 ? ["neg"] : [], value);
    if (result != null) {
      value = clamp(result, range[0], range[1]);
      dispatch("change");
    }
  }
</script>

<div bind:this={container} class="setpoint">
  <span class="value" on:click={valueClick}>{value !== null ? $numFmt(value) : "__"}{units}</span>
  <input
    bind:this={slider}
    bind:value
    type="range"
    class="vranger"
    min={range[0]}
    max={range[1]}
    on:change
    on:touchmove|stopPropagation
    on:pointermove|stopPropagation
  />
</div>

<style lang="scss">
  input[type="range"] {
    transform-origin: center;
    transform: rotate(-90deg) translateX(7px) scale(2.5);
    -moz-transform: rotate(-90deg) translateX(13px) scale(1.8); // Firefox
    width: 80px;
    margin: 0;
  }

  .setpoint {
    cursor: pointer;
    filter: brightness(60%);
    margin: auto;
    color: lightgray;
    .value {
      text-align: center;
      padding: 10px 0;
      display: inline-block;
      min-width: 4em;
      border: 2px lightgray solid;
      font-weight: bold;
      font-size: 2em;
      margin-right: 20px;
    }
  }
</style>
