<script lang="ts">
  import { _ } from "svelte-i18n";
  import { getContext, onMount } from "svelte";
  import { showDialog } from "@/lib/dialogs";
  import AlarmList from "@/tests-dialogs/AlarmList.svelte";
  import Setup from "@/tests-dialogs/Setup.svelte";

  const { background, subTitle } = getContext<AppContext>("app");
  onMount(() => {
    $background = "/img/backgrounds/off.png";
  });

  $: $subTitle = $_("OFF");

  function test(id: string) {
    switch (id) {
      case "alarmlist":
        showDialog(AlarmList);
        break;
      case "setup":
        showDialog(Setup);
        break;
    }
  }
</script>

<div class="test-buttons">
  <p>TEST:</p>
  <button on:click={() => test("alarmlist")}>Alarm list</button>
  <button on:click={() => test("setup")}>Setup...</button>
</div>

<style lang="scss">
  .test-buttons {
    margin: 40px;
    p {
      font-size: 30px;
      font-weight: bold;
    }
  }
</style>
