<script lang="ts">
  import { client } from "@/hmi";
  import { linear } from "@/utils";
  import { getContext, onMount } from "svelte";
  import { FixedBuffer } from "@/fixed-buffer";

  export let pointId: string;
  export let interval: number;

  const { min, max, period, height, enableUpdate: enableUpdateStore } = getContext<GraphContext>("graph");
  const maxRecords = period / interval;

  let canvas: HTMLCanvasElement | undefined;
  let canvasWidth = 0;
  let graphData = new FixedBuffer<number>(maxRecords);
  let stopStream: (() => void) | undefined;

  onMount(() => {
    const startStream = () => {
      let [result, stop] = client.stream(
        "StreamLog",
        (v: number | number[]) => {
          if (Array.isArray(v)) graphData.assign(v); // Array order is oldest first
          else graphData.insert(v);
          update();
        },
        pointId
      );
      stopStream = stop;
      result.catch((e) => {
        console.error(`stream of '${pointId}' failed: ${e}`);
        let timer = setInterval(() => {
          if (client.isOnline()) {
            clearInterval(timer);
            startStream();
          }
        }, 1000);
      });
    };
    startStream();

    return () => {
      stopStream?.call(client);
    };
  });

  $: canvas, update(); // Draw graph when canvas is mounted
  $: if ($enableUpdateStore) update(); // Redraw graph immediately when enableUpdate becomes true

  function update() {
    if (!canvas || !$enableUpdateStore) {
      return;
    }

    let w = canvas.clientWidth;
    let h = canvas.clientHeight;
    canvasWidth = w;

    const xScale = linear([0, maxRecords - 1], [w, 0]);
    const yScale = linear([min, max], [h, 0]);

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, w, h);

    const len = graphData.length();
    if (!len) return;

    // Draw the value graph
    ctx.strokeStyle = "#c8d400";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(w, yScale(graphData.front()));
    for (let i = 1; i < len; i++) {
      ctx.lineTo(xScale(i), yScale(graphData.front(i)));
    }
    ctx.stroke();
  }
</script>

<canvas bind:this={canvas} width={canvasWidth} {height} />

<style>
  canvas {
    width: 100%;
  }
</style>
