<script lang="ts">
  import { onMount } from "svelte";
  import hmi, { config } from "@/hmi";
  import { _, json } from "svelte-i18n";
  import { numFmt } from "@/utils";
  import Fan from "./svg-elements/Fan.svelte";
  import Door from "./svg-elements/Door.svelte";
  import Bulb from "./svg-elements/Bulb.svelte";
  import Steamer from "./svg-elements/Steamer.svelte";
  import Defrost from "./svg-elements/Defrost.svelte";

  let s_light = hmi.getValueStore("LIGHT");
  let s_door = hmi.getValueStore("DOOR");
  let s_curTemp = hmi.getValueStore("cur.T");
  let s_curFanPower = hmi.getValueStore("cur.FanPower");
  let s_curDefrostPower = hmi.getValueStore("cur.DefrostPower");
  let s_setTemp = hmi.getValueStore("set.proofing.T");
  let s_curHumidity = hmi.getValueStore("cur.H");
  let s_setHumidity = hmi.getValueStore("set.proofing.H");
  let s_curSteamWatt = hmi.getValueStore("cur.steamer.W");
  let s_curSteamAmps = hmi.getValueStore("cur.steamer.A");
  let s_statusCode = hmi.getValueStore("cur.proofing.ProgramStatus");

  let curTempText: any, curHumidityText: any, setTempText: any, setHumidityText: any;
  let curSteamerWattText: any, curSteamerAmpsText: any, curFanPowerText: any, curDefrostPowerText: any;
  let statusText: any;

  $: {
    let v = $s_curTemp;
    curTempText = v !== null ? $numFmt(v, $config?.tempDecimals) + $config?.tempUnit || "" : "-";
  }
  $: {
    let v = $s_setTemp;
    setTempText = v !== null ? $numFmt(v, 0) + $config?.tempUnit || "" : "-";
  }
  $: {
    let v = $s_curHumidity;
    curHumidityText = v !== null ? $numFmt(v, 0) + "%" : "-";
  }
  $: {
    let v = $s_setHumidity;
    setHumidityText = v !== null ? $numFmt(v, 0) + "%" : "-";
  }

  $: {
    let v = $s_curSteamWatt;
    curSteamerWattText = v !== null ? $numFmt(v, 0) + "W" : "-";
  }
  $: {
    let v = $s_curSteamAmps;
    curSteamerAmpsText = v !== null ? $numFmt(v, 1) + "A" : "-";
  }

  $: {
    let v = $s_curFanPower;
    curFanPowerText = v !== null ? $numFmt(v, 0) + "%" : "-";
  }
  $: {
    let v = $s_curDefrostPower;
    curDefrostPowerText = v !== null ? $numFmt(v, 0) + "%" : "-";
  }

  $: {
    switch ($s_statusCode) {
      case null:
        statusText = "-";
        break;
      default:
        statusText = ($json("statusCodes") as string[])[$s_statusCode];
        break;
    }
  }

  let showSymbol = true;
  let runFan = true;

  onMount(() => {
    let interval = setInterval(() => {
      showSymbol = !showSymbol;
    }, 1000);
    return () => clearInterval(interval);
  });
</script>

<div class="proofing-details">
  <!-- <img src="/img/proofingStatus.min.svg" style="opacity: 0.2" draggable="false"/> -->
  <Fan run={runFan} style="left: 0px; top: 35px" />
  {#if $s_door === 1}
    <Door style="left: 370px; top: 25px" />
  {/if}
  {#if $s_light === 1}
    <Bulb style="left: 482px; top: 55px" />
  {/if}

  <div class="separator" style="left: 565px; top:0" />

  <Steamer power={showSymbol} steam={showSymbol} water={showSymbol} style="left: 597px; top: 1px" />
  <Defrost wave={showSymbol} coil={showSymbol} style="left: 150px; top: 33px" />

  <div class="cur-value" style="right:470px; bottom:85px">
    {curTempText}
  </div>
  <div class="set-value" style="right:370px; bottom:88px">
    {setTempText}
  </div>

  <div class="cur-value" style="right:470px; bottom:25px">
    {curHumidityText}
  </div>
  <div class="set-value" style="right:370px; bottom:28px">
    {setHumidityText}
  </div>

  <div class="cur-value" style="right:160px; bottom:25px">
    {curSteamerWattText}
  </div>
  <div class="small-value" style="right:70px; bottom:28px">
    {curSteamerAmpsText}
  </div>

  <div class="small-value" style="left:50px; top:200px">
    {curFanPowerText}
  </div>
  <div class="small-value" style="left:210px; top:250px">
    {curDefrostPowerText}
  </div>

  <div class="status" style="top: 350px">
    <div>{$_("Program status")}:</div>
    <div style="font-weight: bold">{statusText}</div>
  </div>
</div>

<style>
  .proofing-details {
    height: 100%;
    margin-top: 20px;
    margin-left: 20px;
    position: relative;
  }
  .proofing-details > div {
    position: absolute;
  }

  .separator {
    height: 100%;
    border: 2px solid gray;
  }

  .cur-value {
    font-weight: bold;
    line-height: 45px;
    font-size: 45px;
  }
  .small-value {
    line-height: 25px;
    font-size: 25px;
  }
  .set-value {
    color: gray;
    line-height: 25px;
    font-size: 25px;
  }

  .status {
    padding: 10px;
    width: 260px;
    background-color: gray;
    color: black;
  }
</style>
