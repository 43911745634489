<script lang="ts">
  import { getContext, onMount } from "svelte";

  let { done } = getContext<DialogContext>("dialog");

  interface SysConfig {
    roomType?: string;
    flexioPort?: string;
  }

  let show = true;
  let dialogRef: HTMLDialogElement;
  let config: SysConfig = {
    roomType: "K",
    flexioPort: "/dev/ttyS1",
  };

  onMount(() => {
    dialogRef.showModal();
  });

  function save() {
    console.table(config);
    dialogRef.close();
  }
</script>

{#if show}
  <dialog
    bind:this={dialogRef}
    on:cancel={() => {
      show = false;
      done(undefined);
    }}
    on:close={() => {
      show = false;
      done(dialogRef.returnValue);
    }}
  >
    <div class="value-entry-table">
      <label for="roomType">Room type:</label>
      <select id="roomType" bind:value={config.roomType}>
        <option selected />
        <option>R</option>
        <option>R-PG</option>
        <option>KR</option>
        <option>FR</option>
        <option>FB</option>
        <option>FB-UV</option>
        <option>K</option>
        <option>KK</option>
        <option>KF</option>
        <option>DC</option>
        <option>SF</option>
        <option>ML</option>
        <option>ML-H</option>
      </select>

      {#if config.roomType}
        <label for="flexio_1">FlexIO port:</label>
        <select id="flexio_1" bind:value={config.flexioPort}>
          <option>/dev/ttyS0</option>
          <option>/dev/ttyS1</option>
          <option selected>/dev/ttyS2</option>
          <option>/dev/ttyS3</option>
          <option>/dev/ttyS4</option>
        </select>
      {/if}
    </div>

    <button on:click={save}>Save</button>
  </dialog>
{/if}

<style lang="scss">
  dialog {
    align-items: unset; /* Dont center vertically in dialog*/
    position: relative;
    width: 100%;
    height: 100%;
    button {
      position: absolute;
      width: fit-content;
      bottom: 0px;
      right: 10px;
    }
  }

  .value-entry-table {
    height: fit-content;
    display: grid;
    grid-template-columns: 160px 250px 1fr;
    gap: 10px;
    label {
      cursor: pointer;
      grid-column-start: 1;
      margin: 8px 5px 2px 5px;
    }
    input[type="text"] {
      width: 100% !important;
      cursor: pointer;
      height: 2rem;
      margin: 5px;
    }
    select {
      text-align: center;
      cursor: pointer;
      width: 100%;
      height: 2rem;
      padding: 0px;
      margin: 5px;
    }
    span {
      display: inline-block;
      margin: 8px 5px 2px 5px;
    }
  }
</style>
