<script lang="ts">
  import { _ } from "svelte-i18n";
  import { getContext, onMount } from "svelte";
  import ModeButton from "@/lib/ModeButton.svelte";
  import AutoGraph from "./svg-elements/AutoGraph.svelte";

  const { config, background, subTitle } = getContext<AppContext>("app");
  $: switchDelay = $config.mode_switch_click_duration_ms * 2 || 5000;

  onMount(() => {
    $background = "/img/backgrounds/auto.png";
  });

  $: $subTitle = $_("Auto");

  function reset() {}
</script>

<div class="auto-mode">
  <AutoGraph />

  <!-- <strong>Status: Waiting for door...</strong>
  <div class="actions">
    <ModeButton duration={switchDelay} active={false} on:click={reset}>Autoreset</ModeButton>
  </div> -->
</div>

<style>
  .auto-mode {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  :global(.actions button) {
    border: none;
  }
</style>
