<svg
  height="339.715"
  width="317.174"
  viewBox="0 0 3.304 3.539"
  shaper:sketchupaddin="2.2.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:shaper="http://www.shapertools.com/namespaces/shaper"
>
  <path
    d="M2.117.64a.144.144 0 00.066.11l.037.015.182.112.16.141.135.166.104.187.07.201.037.21v.215l-.036.21-.071.202-.105.186-.134.166-.16.142-.182.112-.198.08-.21.044-.213.01-.212-.028-.204-.062-.19-.096-.173-.128-.147-.154-.12-.177-.088-.195-.054-.206-.018-.213.018-.213.054-.207.088-.195.12-.177L.82.944.992.817l.117-.06A.144.144 0 001.19.64V.386a.062.062 0 00-.065-.06l-.038.01-.254.118L.6.614.4.81l-.165.227-.124.252-.08.269L0 1.836l.015.28.062.274.107.26.15.237.187.208.22.174.246.134.265.091.277.045.28-.003.277-.05.263-.096.244-.14.216-.178.183-.213.145-.24.102-.261.056-.275.009-.28-.038-.278-.085-.267-.13-.25-.168-.223-.205-.192-.234-.155-.251-.11a.062.062 0 00-.076.058V.64z"
    shaper:pathType="exterior"
    shaper:cutDepth=".197"
  />
  <path
    d="M1.846 1.346V.08A.079.079 0 001.766 0h-.228a.079.079 0 00-.078.079v1.267a.079.079 0 00.079.08h.228a.079.079 0 00.079-.08z"
    shaper:pathType="exterior"
    shaper:cutDepth=".197"
  />
</svg>
