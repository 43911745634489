<script lang="ts">
  import { onDestroy } from "svelte";
  import { fade } from "svelte/transition";

  export let locked: boolean;

  const SIZE = 100;
  const UNLOCK_COUNT = 70;

  let interval: NodeJS.Timer | undefined;
  let counter = 0;
  let canvas: HTMLCanvasElement | undefined;

  function start() {
    counter = 0;
    if (interval) return;
    draw();
    interval = setInterval(() => {
      counter += 1;
      if (counter > UNLOCK_COUNT) {
        locked = false; // Removes the lockscreen
        stop();
      }
      draw();
    }, 20);
  }

  function stop(redraw = false) {
    if (interval) {
      clearInterval(interval);
      interval = undefined;
    }
    if (redraw) draw();
  }

  function cancel() {
    counter = 0;
    stop(true);
  }

  function draw() {
    if (!canvas) return;

    let angle = (Math.PI / 180) * ((360 / UNLOCK_COUNT) * counter);

    let ctx = canvas.getContext("2d");
    if (!ctx) return;

    let w = canvas.clientWidth;
    let h = canvas.clientHeight;
    ctx.clearRect(0, 0, w, h);

    if (!counter) return;

    // Draw a dynamic pie
    ctx.save();
    ctx.strokeStyle = "white";
    ctx.translate(SIZE, SIZE);
    ctx.rotate((Math.PI / 180) * -90);
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.arc(0, 0, SIZE, 0, angle, false);
    ctx.fillStyle = "white";
    ctx.lineTo(0, 0);
    ctx.fill();
    ctx.stroke();
    ctx.restore();
  }

  onDestroy(() => {
    stop();
  });
</script>

{#if locked}
  <div
    class="lock-overlay"
    transition:fade
    on:pointerdown|preventDefault={start}
    on:pointercancel={cancel}
    on:pointerup|preventDefault={cancel}
  >
    <canvas bind:this={canvas} width={SIZE * 2} height={SIZE * 2} />
  </div>
{/if}

<style>
  .lock-overlay {
    display: flex;

    cursor: grab;
    z-index: 10000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    cursor: not-allowed;
    height: 100%;
  }

  canvas {
    margin: auto;

    pointer-events: none;
    background-color: transparent;
    opacity: 0.8;
  }
</style>
