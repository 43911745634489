<script lang="ts">
  import { _ } from "svelte-i18n";
  import { getContext, onMount } from "svelte";
  import hmi, { farenheight, config } from "@/hmi";
  import { numFmt } from "@/utils";
  import Setpoint from "./components/Setpoint.svelte";
  import { Swiper, SwiperSlide } from "swiper/svelte";

  import Graph from "@/lib/Graph.svelte";
  import LineGraph from "@/lib/LineGraph.svelte";

  const SETPREFIX = "set.storage.";

  const { background, subTitle } = getContext<AppContext>("app");

  onMount(() => {
    $background = "/img/backgrounds/storage.png";
  });

  $: $subTitle = $_("Storage");

  let s_curTemp = hmi.getValueStore("cur.T");
  let s_setTemp = hmi.getValueStore(SETPREFIX + "T");
  let s_curInputs = hmi.getValueStore("cur.Inputs");

  let activeSlideIndex = 0;

  let curTempText: any;
  $: {
    let v = $s_curTemp;
    curTempText = v !== null ? $numFmt(v, $config?.tempDecimals) + $config?.tempUnit || "" : "-";
  }

  $: setTemp = $s_setTemp;

  function commitTemperatur() {
    hmi.setValue(SETPREFIX + "T", setTemp);
  }

  function alwaysCelcius(v: number): number {
    if (!$farenheight) return v;
    return (v - 32) / 1.8; // F -> C
  }

  function onSlideChange(e: any) {
    const [swiper] = e.detail;
    activeSlideIndex = swiper.activeIndex;
  }
</script>

<Swiper on:slideChange={onSlideChange}>
  <SwiperSlide>
    <div class="storage-mode" style="margin-top: 140px">
      <!-- Temperatur -->
      <div class="row">
        <div class="cur-value">{curTempText}</div>
        <Setpoint
          bind:value={setTemp}
          name={$_("Temperature") + " " + $_("setpoint")}
          units={$config?.tempUnit || ""}
          range={$config?.storage.Trange}
          on:change={commitTemperatur}
        />
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
    <div class="storage-mode" style="padding: 50px">
      <h3>INPUTS: {$s_curInputs !== null ? $s_curInputs.toString(2) : "-"}</h3>
    </div>
    <div class="storage-mode" style="padding:10px; position:absolute; left:0; right:0; bottom:20px">
      <!--
        Add this property to Graph to limit updates enableUpdate={activeSlideIndex === 1}
        FIXME: Not working for 1st update
       -->
      <Graph
        height={160}
        min={alwaysCelcius($config?.storage.Trange[0])}
        max={alwaysCelcius($config?.storage.Trange[1])}
        period={60 * 60}
        marker={alwaysCelcius($s_setTemp)}
      >
        <LineGraph pointId="cur.T" interval={2} />
      </Graph>
    </div>
  </SwiperSlide>
</Swiper>

<style lang="scss">
  .storage-mode {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }
  .row {
    display: flex;
  }
  .cur-value {
    white-space: nowrap;
    padding-bottom: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 8em;
    width: 60%;
  }
</style>
