<script lang="ts">
  import { afterUpdate, getContext, onDestroy, onMount } from "svelte";

  export let contextName = "canvas";
  export let width: number;
  export let height: number;
  export let progress: number;
  export let color: string;

  const { config } = getContext<AppContext>("app");

  const { register, deregister, invalidate } = getContext<CanvasContext>(contextName);

  onMount(() => {
    register(draw);
    invalidate();
    return () => deregister(draw);
  });

  $: progress, width, height, color, invalidate();

  afterUpdate(invalidate);

  onDestroy(invalidate);

  function draw(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = color;
    ctx.globalAlpha = 0.6;
    let h = height * (progress / 100);
    ctx.fillRect(0, height - h, width, h);
  }
</script>
