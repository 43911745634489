<script lang="ts">
  import { Modes } from "@/hmi/index.js";

  import ModeViewContainer from "./ModeViewContainer.svelte";

  import Off from "@/modes/Off.svelte";
  import Auto from "@/modes/Auto.svelte";
  import Storage from "@/modes/Storage.svelte";
  import Proofing from "@/modes/Proofing.svelte";

  export let mode: number;

  let views = [
    { key: Modes.OFF, component: Off },
    { key: Modes.AUTO, component: Auto },
    { key: Modes.STORAGE, component: Storage },
    { key: Modes.PROOFING, component: Proofing },
  ];
</script>

{#each views as { key, component } (key)}
  <ModeViewContainer {mode} selected={mode === key}>
    <svelte:component this={component} />
  </ModeViewContainer>
{/each}
