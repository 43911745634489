<script lang="ts">
  import { locale, locales } from "svelte-i18n";

  let showDropdown = false;

  function select(l: string) {
    showDropdown = false;
    localStorage.setItem("hmi.locale", l);
    locale.set(l);
  }

  function toggleDropdown() {
    showDropdown = !showDropdown;
  }
  function cancel() {
    showDropdown = false;
  }
</script>

<div class="container" on:click|stopPropagation={toggleDropdown}>
  <img src={`/flags/${$locale}.png`} draggable="false" />
  {#if showDropdown}
    <div class="backdrop" on:pointerup={cancel} />
    <ul>
      {#each $locales as l}
        <li class={$locale === l ? "active" : ""} on:click|stopPropagation={() => select(l)}>
          <img src={`/flags/${l}.png`} draggable="false" />
        </li>
      {/each}
    </ul>
  {/if}
</div>

<style>
  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    pointer-events: all;
    background-color: transparent;
  }
  .container {
    display: inline;
    position: relative;
    cursor: pointer;
  }
  .container > img {
    margin-top: -3px;
  }
  ul {
    z-index: 11;
    box-shadow: 2px 2px 25px black;
    background-color: #222;
    padding: 10px;
    position: absolute;
    left: -20px;
  }
  li {
    min-width: 40px;
    list-style: none;
  }
  li.active {
    background-color: var(--active-link);
  }
  img {
    pointer-events: none;
  }
  li img {
    padding: 10px;
  }
</style>
